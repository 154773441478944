import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ContactSection from '../components/contact-section';

const Container = styled.div`
  width: 80%;
  margin: auto;
  max-width: 960px;
`;

const ContentFrame = styled.fieldset`
  margin: 5rem 0;
  padding: 4rem 8rem;
  border: 2px solid black;

  @media (max-width: 1000px) {
    margin: 4rem 0;
    padding: 3rem 6rem;
  }

  @media (max-width: 750px) {
    margin: 2rem 0;
    padding: 1rem 2rem;
  }
`;

const Title = styled.legend`
  text-align: center;
  font-size: 2.5rem;
  padding: 0 2rem;

  @media (max-width: 1000px) {
    font-size: 2rem;
  }

  @media (max-width: 750px) {
    font-size: 1.25rem;
    padding: 0 0.7rem;
  }
`;

const ContentText = styled.p`
  line-height: 1.6rem;
  font-size: 1.15rem;

  @media (max-width: 1000px) {
    font-size: 1rem;
  }

  @media (max-width: 750px) {
    font-size: 0.9rem;
  }
`;

const IndexPage = () => (
  <Layout home={true}>
    <SEO title="Home" />
    <Container>
      <ContentFrame>
        <Title>What is a 1031 exchange?</Title>
        <ContentText>
          Section 1031 of the tax code (see below) provides for the deferral of
          gains on real property used in business or for investment. For
          example, an investor sells a rental home for $200,000 realizing a
          profit of $100,000 - that investor uses a 1031 exchange as the vehicle
          to defer paying capital gains taxes on the sale of that rental house.
          The seller must use the funds from the sale to buy another
          “replacement” property as long as the new property is of greater or
          equal value, and it is not one’s primary residence.
        </ContentText>
        <ContentText>
          In short, it’s akin to trading one property for another as long as all
          the would-be gained funds go toward the new property. In the example
          above, assume the seller now identifies and purchases a new rental for
          $300,000. The seller defers the tax on the $100,000 gain by reducing
          the basis in the replacement property by the $100,000 gain.
        </ContentText>
      </ContentFrame>
    </Container>
    <Container>
      <ContentFrame>
        <Title>Why perform an exchange?</Title>
        <ContentText>
          The main reason to perform a 1031 exchange is for the tax deferment
          itself - however, there are a few other opportunities made more
          readily available through an exchange.
        </ContentText>
        <ContentText>
          Business owner? An exchange could make the possibility of expanding
          your operations a reality. Invest in a more profitable area without
          needing to pay the cost of sale.
        </ContentText>
        <ContentText>
          Own rental properties? Swap your current rentals for more rewarding
          ones that will see more returned to you month over month.
        </ContentText>
        <ContentText>
          Expecting disagreements over estate inheritance? You can diversify
          your wealth by exchanging a single estate for multiple smaller ones
          that can be easily divided between heirs so everyone can have their
          own way.
        </ContentText>
      </ContentFrame>
    </Container>
    <Container>
      <ContentFrame>
        <Title>Where do we come in?</Title>
        <ContentText>
          By law, a third party is required to facilitate the exchange to ensure
          that the investor is not receiving any of the funds being transferred.
          Known as a Qualified Intermediary, this entity is responsible for
          holding the funds and then allocating it to the new property.
        </ContentText>
        <ContentText>
          That’s where we come in. Having an experienced Qualified Intermediary
          can help avoid any missteps that might end up in a problem with the
          IRS. Our team is backed by over 30 years of experience in the
          industry, including veterans of real estate, CPAs, and financial
          advisory. We’ll handle the transferring and provide our expert advice
          along the way to ensure you make the best deal possible.
        </ContentText>
      </ContentFrame>
    </Container>
    <ContactSection
      header="Ready to get started?"
      subheader="Let us help you take the next step!"
      buttonText="I'm ready!"
    />
  </Layout>
);

export default IndexPage;
